<template>
    <div v-if="params.data">
      <v-row>
        <v-col cols="10" offset="1" class="py-0 mt-8">
          <p><label class="font-weight-bold">Email:&nbsp;</label><a :href="'mailto:'+params.data.email">{{ params.data.email }}</a>&nbsp; <i @click="copytoToClipboard(params.data.email);" class="flaticon2-copy text-muted icon-1x pr-2 cursor-pointer"></i>&nbsp;&nbsp; <label class="font-weight-bold">Phone:&nbsp;</label><a :href="'tel:'+formatNumber">{{ formatNumber }}</a>&nbsp; <i @click="copytoToClipboard(formatNumber);" class="flaticon2-copy text-muted icon-1x pr-2 cursor-pointer"></i></p>
        </v-col>
      </v-row>
    </div>
</template>

<script>


    export default {
        name: "ActionsRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        methods: {
          copytoToClipboard (text) {
            navigator.clipboard.writeText(text)
          },
        },
       computed: {
          formatNumber(){
            if(!this.params.data.phone) return '-';
            var cleaned = ('' + this.params.data.phone).replace(/\D/g, '');
            var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
              return [ '(', match[2], ') ', match[3], '-', match[4]].join('');
            }
            return this.params.data.phone;
          },
       },
    }
</script>

