<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card">
    <!--begin::Header-->
    <div class="card-header border-0 py-5 d-flex justify-content-between">
      <div class="font-weight-bolder text-primary text-uppercase font-size-h2" v-if="members_count == 0">
         Active Memberships
      </div>
      <div class="font-weight-bolder text-primary text-uppercase font-size-h2" v-else>
         Active Memberships ({{ members_count }})
      </div>
      <div class="d-flex">
        <b-form-select v-model="currentOffice" :options="offices" size="md" class="mr-2" style="width: 350px!important;"></b-form-select>
        <b-form-input v-model="search_text" placeholder="Search..."  style="width: 300px!important;"></b-form-input>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <ag-grid-vue
                rowHeight="65"
                class="ag-theme-material ag-grid-vue"
                :columnDefs="columnDefs"
                :pagination="false"
                @grid-ready="onGridReady"
                :defaultColDef="defaultColDef"
                :masterDetail="true"
                :detailCellRenderer="detailCellRenderer"
                :detailRowHeight="detailRowHeight"
                :groupDefaultExpanded="groupDefaultExpanded"
                :frameworkComponents="frameworkComponents"
                :rowData="items"
                @first-data-rendered="onFirstDataRendered"
                >

            </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import { GET_MEMBERS } from "@/core/services/store/profile.module";
import {GET_OFFICES} from "@/core/services/store/profile.module";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { AgGridVue } from 'ag-grid-vue';

import ActionsRenderer from './Render/ActionsRenderer.js';
import NameRenderer from './Render/NameRenderer.js';
import MembershipRenderer from './Render/MembershipRenderer.js';
import { LicenseManager } from "ag-grid-enterprise";
import 'ag-grid-enterprise';

import MemberDetailRenderer from './Render/MemberDetailRenderer.js';

LicenseManager.setLicenseKey("Servoy_B.V_Servoy_7Devs_200Deployment_11_October_2020__MTYwMjM3MDgwMDAwMA==26d908e26f73b44683ba7a5dfdd00755");

export default {
  name: "transactions",
  components: {
     AgGridVue,
  },
  data() {
    return {
      offices : [],
      currentOffice: null,
      search_text: '',
      members_count: 0,
      deleteConfirmDialog: true,
      selectedPk: null,
      formData:{
        'min_dte' : null,
        'max_dte' : null,
        'cent' : null,
      },
      txtFile: null,
      items: [],
      detailCellRenderer: null,
      detailRowHeight: null,
      groupDefaultExpanded: null,
      columnDefs:  [
       {
         headerName:'Name',
         field: 'name',
         maxWidth: 300,
         cellRenderer: 'agGroupCellRenderer',
         cellStyle: {
           textAlign: 'center',
           fontWeight:'600'
         }
       },
       {
         headerName: 'Company',
         field: 'company',
         cellRenderer: 'nameRenderer',
         cellStyle: {
           textAlign: 'center',
         },
         valueFormatter: companyFormatter
       },
       {
         headerName: 'Office',
         field: 'office',
         cellRenderer: 'membershipRenderer',
         cellStyle: {
           textAlign: 'center',
         }
       },
        {
         headerName: 'Monthly Fee',
         field: 'monthly_fee',
          maxWidth: 120,
          cellStyle: {
           textAlign: 'center',
          },
          valueFormatter: currencyFormatter
       },
        { headerName: 'Location', field: 'location', hide: true },
     ],
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        sortable: true,
        suppressMenu: true,
        flex: 1,
      },
      frameworkComponents : null,
      edit_or_create: true
    };
  },
  computed: {
    ...mapState({
      errors: state => state.symbol.errors
    }),
    ...mapGetters(["layoutConfig"]),
    checkForm(){
      return !(this.formData.min_dte !== null  && this.formData.max_dte !== null && this.formData.cent !== null)
    }
  },
  methods: {
     onFirstDataRendered(params) {

    },
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridColumnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    getOffices(){
      this.$store
          .dispatch(GET_OFFICES)
          .then((data) => {
            this.offices = data;
            if(this.offices.length > 0)
              if(this.currentOffice == null)
              this.currentOffice = this.offices[0].value
          });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.loadMembers();
     },
    loadMembers(){
      if(this.currentOffice !== null){
        this.gridApi.showLoadingOverlay();
         this.$store
          .dispatch(GET_MEMBERS, this.currentOffice)
          .then((data) => {
            this.items = data;
            this.members_count = this.items.length;
            this.gridApi.hideOverlay();
          })
      }
      }

  },
  beforeMount() {
     this.frameworkComponents = {
       'actionsRenderer': ActionsRenderer,
       'nameRenderer' : NameRenderer,
       'membershipRenderer' : MembershipRenderer,
       'memberDetailRenderer' : MemberDetailRenderer
     };
  },
  watch: {
    search_text(new_value, old_value) {
       this.gridApi.setQuickFilter(new_value);
    },
    currentOffice(new_value){
      localStorage.setItem('currentOffice', this.currentOffice)
      this.loadMembers()
    }
  },
  created() {
    if(localStorage.getItem('currentOffice'))
      this.currentOffice = localStorage.getItem('currentOffice');
    this.getOffices();
    this.detailCellRenderer = 'memberDetailRenderer';
    this.detailRowHeight = 70;
    this.groupDefaultExpanded = 0;
  },
};

window.currencyFormatter = function currencyFormatter(params) {
  return '$' + params.value;
};

window.companyFormatter = function companyFormatter(params) {
  if(params.value) return params.value;
  return '-';
};

window.phoneFormatter = function phoneFormatter(params) {
  if(!params.value) return '-';
  var cleaned = ('' + params.value).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return [ '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return params.value
};

</script>
<style>
.ag-header-cell-label {
   justify-content: center;
}
 .btn-create {
    margin-left: 10px;
    border-radius: 33px;
    height: 28px;
    padding: 0rem 1rem!important;
 }
 .btn-create i{
    font-size: 1rem!important;
    padding-right: 0.1rem!important;
 }
 .modal-input{
   height: 45px!important;
 }
  .ag-grid-vue{
       width: 100%!important;
       height: calc(100vh - 230px)!important;
    }

    /* This CSS is to not apply the border for the column having 'no-border' class */
    .no-border.ag-cell:focus{
        border:none !important;
        outline: none;
    }
    .ag-cell-focus,.ag-cell-no-focus{
        border:none !important;
    }

    .ag-row-selected {
        background-color: transparent!important;
    }
    .ag-theme-material .ag-details-row {
       padding: 10px 40px 10px 40px!important;
    }

    div.ag-theme-material div.ag-row {
      font-size: 15px !important;
      padding-top: 10px!important;
    }
    .container {
      max-width: 98vw!important;
    }
</style>
