<template>
    <div v-if="params.data">
      <h6 class="mb-0 font-weight-normal text-center">{{ params.data.office? params.data.office: '-' }}</h6>
       <p class="text-muted py-0 mt-n2 text-center">  <i class="flaticon2-calendar text-muted icon-1x pr-2"></i>{{ params.data.start_date }} - {{ params.data.end_date }} </p>
    </div>
</template>

<script>

    export default {
        name: "NameRenderer",
        components: {
        },
        props: {
            params: {
                type: Object,
                required: true
            }
        },
        data() {
            return {

            }
        },
        computed:{

        },
        methods: {

        }
    }
</script>

